<template>
    <div class="d-flex align-center">
        <span>{{ job.job_status }}</span>
        <v-progress-circular v-if="!jobComplete()" class="ml-1" color="primary" size="20" width="3" indeterminate />
    </div>
</template>

<script>
import jobService from '@/services/jobs'

export default {
    name: "JobStatus",
    props: {
        job: {
            type: Object,
            required: true
        }
    },
    mounted () {
        this.interval = setInterval(this.checkJobStatus, 2000)
    },
    data: () => ({
        interval: null
    }),
    computed: {
        jobLocal: {
            get () { 
                return this.job 
            },
            set (value) {
                this.$emit('job-updated', value)
            }
        }
    },
    methods: {
        jobComplete() {
            return jobService.isComplete(this.job)
        },
        async checkJobStatus() {
            if ( !this.jobComplete() ) {
                const jobResponse = await jobService.get(this.job.id)
                this.jobLocal = Object.assign({}, jobResponse)
            } else {
                clearInterval(this.interval)
            }
        }
    },
    beforeDestroy () {
        clearInterval(this.interval)
    }
}
</script>
